var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/admin"
    }
  }, [_vm._v(" Manajemen Admin ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Ubah" : "Tambah") + " Admin ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px",
      "mb": "50px"
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Ubah" : "Tambah") + " Admin ")]), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-w": "590px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.firstName === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nama Depan",
      "height": "62px"
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nama Belakang",
      "height": "62px"
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.$v.email.$invalid,
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Email ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "disabled": _vm.id,
      "variant": _vm.id ? 'filled' : '',
      "type": "email",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Email",
      "height": "62px"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between"
    }
  }, [!_vm.id ? _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "gray",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px",
      "color": "#555555"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" Batal ")]) : _vm._e(), _vm.id ? _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "bg": "red.900",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_vm._v(" Hapus ")]) : _vm._e(), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "font-size": "18px",
      "is-disabled": _vm.isDisabledSubmit
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Simpan ")])], 1)], 1), _c('DeleteConfirm', {
    attrs: {
      "content": _vm.deletedText,
      "is-open": _vm.isOpen
    },
    on: {
      "on-delete": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }